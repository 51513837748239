import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Cards from "../components/Cards"
import PageFooter from "../components/PageFooter"
import Layout from "../layouts/Layout"

const PortfolioItem = props => {
  const {
    gallery,
    name,
    summary,
    thumbnail,
    concept,
    briefingSummary,
  } = props.data.contentfulPortfolio

  const shuffledCards = props.data.allPortfolioItems.nodes
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    .slice(0, 3)

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.gatsbyImageData.images.fallback.src}
      />
      <div className="bg-gray-0 pt-12">
        <div className="container">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-12 text-center">
            {name}
          </h1>
        </div>

        <div>
          <div className="py-4 lg:pb-12">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2 xl:w-3/12 p-8 md:pb-0">
                <div className="">
                  <div className="p-4">
                    {briefingSummary && (
                      <p className="text-left">
                        {briefingSummary.briefingSummary}
                      </p>
                    )}
                    {concept && (
                      <>
                        <br />
                        <p className="text-left">{concept.concept}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 xl:w-9/12 pt-4">
                <div className="p-4">
                  {gallery &&
                    gallery.map(galleryImage =>
                      galleryImage.gatsbyImageData ? (
                        <GatsbyImage
                          image={galleryImage.gatsbyImageData}
                          alt={galleryImage.title}
                        />
                      ) : (
                        <img
                          src={galleryImage.gatsbyImageData}
                          alt={galleryImage.title}
                        />
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-12 lg:py-16">
        <div className="container">
          <h2 className="text-3xl sm:text-4xl leading-tight font-extrabold tracking-tight text-gray-900 mb-8">
            Alguns outros projetos
          </h2>
        </div>
        <Cards items={shuffledCards} hideLastItemOnMobile={true} />
      </div>

      <div className="py-12 px-4 lg:py-16">
        <PageFooter />
      </div>
    </Layout>
  )
}

export default PortfolioItem

export const query = graphql`
  query PortfolioItemQuery($slug: String!) {
    contentfulPortfolio(slug: { eq: $slug }) {
      description {
        description
      }
      gallery {
        id
        gatsbyImageData(layout: FULL_WIDTH)
        title
      }
      name
      related {
        ...PortfolioCard
      }
      summary
      client
      briefingSummary {
        briefingSummary
      }
      concept {
        concept
      }
      thumbnail {
        gatsbyImageData(layout: FIXED)
      }
      url
    }
    allPortfolioItems: allContentfulPortfolio(
      filter: { projectType: { glob: "Branding" } }
    ) {
      nodes {
        ...PortfolioCard
      }
    }
  }
`
